import React, { useState } from 'react';
import cls from './styles.module.scss';

function Footer() {

    return (
        <div className={cls.footer_container}>
            <div className={cls.footer_block}>
                {/* <div className={cls.header}>
                    <div className={cls.logo}>
                        <img src={LogoCenter} alt='' />
                    </div>
                </div>
                <div className={cls.license}>
                    <p>ИП Олежичкин А. В.</p>
                    <p>ОГРНИП 308554333600262</p>
                </div> */}
            </div>
        </div>
    );
}

export default Footer;