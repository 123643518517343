import { useState } from 'react';
import cls from './styles.module.scss'

function Home() {

    return (
        <div className={cls.home_container}>
        </div>
    )
}

export default Home;