import React, { useState } from 'react';
import Logo from '../../shared/assets/Logo.png'
import Menu from '../../shared/icons/Menu.svg'
import Message from '../../shared/icons/Message.svg'
import Close from '../../shared/icons/Close.svg'
import cls from './styles.module.scss';

function Layout() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen((prevState) => !prevState);
    };

    return (
        <div className={cls.layout_container}>
            <div className={cls.layout_block}>
                <div className={cls.menu} onClick={toggleMenu}>
                    <img src={Menu} alt='' />
                </div>
                <div className={cls.logo}>
                    <img src={Logo} alt='' />
                </div>
                <div className={cls.message}>
                    <img src={Message} alt='' />
                </div>
            </div>
            {isMenuOpen &&
                <div className={cls.list_block}>
                </div>
            }
        </div>
    );
}

export default Layout;